<template>
  <div class="login">
    <h2>{{'auth.welcome' | translate}}</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <div class="input-group">
          <input type="text" id="email" name="email" required="required" v-model="credentials.email"/>
          <label class="control-label" for="email">{{'auth.email' | translate}}</label><i class="bar"></i>
        </div>
      </div>
      <div class="form-group mt-5">
        <div class="input-group">
          <input type="password" id="password" name="password" required="required" v-model="credentials.password"/>
          <label class="control-label" for="password">{{'auth.password' | translate}}</label><i class="bar"></i>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between down-container">
        <router-link class="link" :to="{name: 'auth.reset-password-email'}">
          {{ 'auth.forgotPassword' | translate }}
        </router-link>
        <Button classes="btn btn-primary" :loading="loading">
          {{'auth.login' | translate}}
        </Button>
        <router-link v-if="false" class='link' :to="{name: 'signup'}">{{'auth.createAccount' | translate}}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'login',

  data () {
    return {
      credentials: {
        email: '',
        password: '',
      },
      redirectUrl: undefined,
    }
  },

  mounted() {
    const query = this.$route.query;
    if (query.path) {
      this.redirectUrl = query.path + '?'
      delete query.path

      Object.keys(query).forEach(key => {
        this.redirectUrl += '&' + key + '=' + query[key]
      });
    }
  },

  methods: {
    login () {
      this.$store.dispatch('auth/login', this.credentials)
        .then((res) => {
          if (this.redirectUrl)
          {
            this.$router.push(this.redirectUrl)
          } else {
            const url = res.data.role_group == 'Admin' ? 'admin' : (res.data.role_group == 'Agency' ? 'agency' : 'business')
            this.$router.push(`/${url}`)
          }
        })
        .catch(() => {})
    },
  },

  computed: {
    loading () { 
      return this.$store.state.auth.loading
    }
  }
}
</script>

<style lang="scss">
  .login {
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
      .down-container {
        .link {
          margin-top: 2rem;
        }
      }
    }

    h2 {
      text-align: center;
    }
    width: 21.375rem;

    .link {
      color: #3578c6;
    }
  }
</style>
